import { z } from 'zod'
import Model from '../Model'
import Job from './Job'
import Equipment from './Equipment'

export default class Flag extends Model {
  static readonly tableName: string = 'flags'
  static readonly apiEndPoint: string = 'api/flags'
  static readonly sortKey: string = 'created_at'
  static readonly sortDirection = 'desc'
  static readonly bypassPendingRequests:
    | 'all'
    | ('delete' | 'saveMany' | 'create' | 'update')[] = []
  static readonly pusherChannelPrefix = 'flags'

  user_id: string | number = ''
  description: string = ''
  flaggable_id: string | number = ''
  flaggable_type: 'App\\Models\\Equipment' | 'App\\Models\\Job' =
    'App\\Models\\Job'
  assigned_id: string | number = ''
  resolved_id: string | number = ''
  resolved_at: Date | string = ''
  flaggable?: Job | Equipment

  static ZodValidator = z.object({
    description: z
      .string({
        required_error: 'The description field is required.',
      })
      .min(1, 'The description field is required.')
      .max(20000),
    flaggable_id: z.coerce
      .string({
        required_error: 'Flags must belong to a job or equipment.',
      })
      .min(1, 'Flags must belong to a job or equipment.'),
    flaggable_type: z.enum(['App\\Models\\Job', 'App\\Models\\Equipment'], {
      required_error: 'Flags must belong to a job or equipment.',
    }),
    assigned_id: z.coerce.string().nullable(),
    resolved_id: z.coerce.string().nullable(),
    resolved_at: z.union([z.date(), z.string()]).nullable(),
  })

  constructor(payload: Partial<Flag> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
