import { z } from 'zod'
import Model from '../Model'
import { CustomFieldValue } from './CustomFieldValue'

export default class JobItem extends Model {
  static readonly tableName: string = 'jobItems'
  static readonly apiEndPoint: string = 'api/job_items'
  static readonly sortKey: string = 'created_at'
  static readonly sortDirection = 'desc'
  static readonly bypassPendingRequests:
    | 'all'
    | ('delete' | 'saveMany' | 'create' | 'update')[] = []
  static readonly pusherChannelPrefix = 'job_items'

  job_id: string | number = ''
  item_id: string | number = ''
  details: string = ''
  estimated_qty: number = 0
  actual_qty: number = 0
  custom_field_values: CustomFieldValue[] = []
  created_id?: string | number = ''
  updated_id?: string | number = ''
  changed?: boolean

  static ZodValidator = z.object({
    details: z.string().max(20000).nullable(),
    job_id: z.coerce
      .string({
        required_error: 'The job field is required.',
      })
      .min(1, 'The job field is required.'),
    item_id: z.coerce
      .string({
        required_error: 'The item field is required.',
      })
      .min(1, 'The item field is required.'),
    estimated_qty: z.coerce.number().default(0),
    actual_qty: z.coerce.number().default(0),
    custom_field_values: z.array(CustomFieldValue.ZodValidator),
  })

  constructor(payload: Partial<JobItem> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
