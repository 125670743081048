<template>
  <Modal>
    <template #title>{{
      model.id ? 'Edit Equipment Hours' : 'Add Equipment Hours'
    }}</template>
    <SelectMenu
      v-model="model.hours_type_id"
      @update:model-value="errors.hours_type_id = ''"
      :error="errors.hours_type_id"
      :options="filteredHoursTypes"
      valueKey="id"
      labelKey="name"
      subtitle="Equipment Hours"
    >
      Type
    </SelectMenu>
    <EquipmentInput
      v-model="model.equipment_id"
      @update:model-value="errors.equipment_id = ''"
      :error="errors.equipment_id"
      subtitle="Equipment Hours"
    />
    <div class="grid w-full grid-cols-2 gap-2">
      <Textfield
        v-if="showEstimated"
        v-model="model.estimated_hours"
        @keydown="errors.estimated_hours = ''"
        id="estimated_hours"
        :error="errors.estimated_hours"
        type="number"
        >Estimated Hours</Textfield
      >
      <Textfield
        v-model="model.actual_hours"
        @keydown="errors.actual_hours = ''"
        id="actual_hours"
        :error="errors.actual_hours"
        type="number"
        >Total Hours</Textfield
      >
    </div>
    <Textarea
      v-model="model.details"
      @update:model-value="errors.details = ''"
      :error="errors.details"
      >Details</Textarea
    >
    <template #actions>
      <CloseButton @click="$modals.close()" />
      <DeleteButton v-if="showDelete" @click="deleteModel()" />
      <SaveButton @click="save()" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { useCan } from '~/plugins/auth'
import { useSettings } from '~/composables/useSettings'
import Modal from '~/components/Modal.vue'
import SelectMenu from '~/components/SelectMenu.vue'
import EquipmentInput from '~/components/EquipmentInput.vue'
import Textfield from '~/components/Textfield.vue'
import Textarea from '~/components/Textarea.vue'
import CloseButton from '~/components/CloseButton.vue'
import DeleteButton from '~/components/DeleteButton.vue'
import SaveButton from '~/components/SaveButton.vue'
import JobEquipmentHour from '~/database/models/JobEquipmentHour'
import { AxiosError } from 'axios'
import { useModelEdits } from '~/database'
import Job from '~/database/models/Job'
import { useStore } from '~/plugins/store'

const props = defineProps({
  jobEquipmentHour: {
    type: Object as PropType<Partial<JobEquipmentHour>>,
    default: () => ({}),
  },
  resolve: {
    type: Function as PropType<Function>,
    required: true,
  },
  axiosError: {
    type: [Object, undefined] as PropType<AxiosError | undefined>,
    default: () => undefined,
  },
  bypassQueue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  job: {
    type: Object as PropType<Job>,
    required: true,
  },
})

const { model, errors, showDelete, deleteModel, save } =
  useModelEdits<JobEquipmentHour>(
    props.jobEquipmentHour,
    JobEquipmentHour,
    ['hours_type_id', 'equipment_id', 'estimated_hours', 'actual_hours'],
    props.resolve,
    {
      axiosError: props.axiosError,
      bypassQueue: props.bypassQueue,
      deleteMessage: 'Delete these equipment hours?',
    },
  )

if (props.job.id) {
  model.value.job_id = props.job.id
}

//hours types
const store = useStore()
const filteredHoursTypes = computed(() => {
  return store.hoursTypes.set.value.filter((type) => {
    return (
      type.id == props.jobEquipmentHour.hours_type_id ||
      (type.active &&
        type.category_list.some((id) => {
          return id == props.job?.category_id
        }))
    )
  })
})
if (filteredHoursTypes.value.length == 1) {
  model.value.hours_type_id = filteredHoursTypes.value[0].id
}

//estimated qty
const settings = useSettings()
const can = useCan()
const showEstimated = computed(() => {
  const setting = settings.getSetting('show_estimated_equipment_hours')
  return setting == 'all' || (setting == 'admin' && can('edit', 'jobs_admin'))
})
</script>

<style scoped></style>
