import { z } from 'zod'
import Model from '../Model'
import { Address } from './Address'
import Task from './Task'
import JobStatus from './JobStatus'
import JobEquipmentHour from './JobEquipmentHour'
import JobLaborHour from './JobLaborHour'
import JobItem from './JobItem'
import Note from './Note'
import Flag from './Flag'
import File from './File'
import Image from './Image'
import { CustomFieldValue } from './CustomFieldValue'
import Customer from './Customer'

export default class Job extends Model {
  static readonly tableName: string = 'jobs'
  static readonly apiEndPoint: string = 'api/jobs'
  static readonly sortKey: string = 'sort'
  static readonly pusherChannelPrefix = 'jobs'
  static readonly bypassPendingRequests:
    | 'all'
    | ('delete' | 'saveMany' | 'create' | 'update')[] = [
    'delete',
    'saveMany',
    'create',
  ]

  active: boolean = true
  actual_hours: number = 0
  address?: Address
  address_string?: string
  category_id: string | number = ''
  current_status_id: string | number = ''
  current_status_active: boolean = true
  current_status_archived: boolean = false
  custom_field_values?: CustomFieldValue[] = []
  customer_id: string | number = ''
  customer_name?: string
  estimated_hours: number = 0
  is_template: boolean = false
  job_template_id: string | number = ''
  name: string = ''
  sort: number = 0
  is_flagged?: boolean
  statuses: JobStatus[] = []
  estimated_labor_hours: number = 0
  actual_labor_hours: number = 0
  estimated_equipment_hours: number = 0
  actual_equipment_hours: number = 0
  job_template_name?: string
  job_template_color?: string
  color: string = ''
  tasks?: Task[]
  items?: JobItem[]
  equipment_hours?: JobEquipmentHour[]
  labor_hours?: JobLaborHour[]
  flags?: Flag[]
  notes?: Note[]
  files?: File[]
  images?: Image[]
  customer?: Customer

  static ZodValidator = z.object({
    current_status_id: z.coerce
      .string({
        required_error: 'Current status is required',
      })
      .min(1, { message: 'Current status is required' }),
    items: z.array(JobItem.ZodValidator).optional(),
  })

  constructor(payload: Partial<Job> = {}) {
    super(payload)
    Object.assign(this, payload)
  }

  static localDataFilterMethod<T extends Model>(
    model: T,
    queryParameters: Record<string, string | number | Date | boolean>,
  ): boolean {
    const job = model as unknown as Job
    if (queryParameters.start && queryParameters.end) {
    }
    if (
      queryParameters.customer_id &&
      job.customer_id != queryParameters.customer_id
    ) {
      return false
    }
    if (
      queryParameters.category_id &&
      queryParameters.category_id == 'flagged' &&
      !job.is_flagged
    ) {
      return false
    }
    if (
      queryParameters.category_id &&
      queryParameters.category_id != 'flagged' &&
      job.category_id != queryParameters.category_id
    ) {
      return false
    }
    if (queryParameters.templates_only && !job.is_template) {
      return false
    }
    if (!queryParameters.templates_only && job.is_template) {
      return false
    }
    if (
      queryParameters.current_status_id &&
      job.current_status_id != queryParameters.current_status_id
    ) {
      return false
    }
    if (queryParameters.active && !job.current_status_active) {
      return false
    }
    if (!queryParameters.with_archived_jobs && job.current_status_archived) {
      return false
    }
    if (queryParameters.name) {
      const searchString = String(queryParameters.name).toLowerCase()
      if (!job.name.toLowerCase().includes(searchString)) {
        return false
      }
    }
    if (queryParameters.address) {
      const searchString = String(queryParameters.address).toLowerCase()
      if (!job.address_string?.toLowerCase().includes(searchString)) {
        return false
      }
    }

    if (queryParameters.search) {
      const searchString = String(queryParameters.search).toLowerCase()
      if (
        !job.name.toLowerCase().includes(searchString) &&
        !job.address_string?.toLowerCase().includes(searchString) &&
        !job.customer_name?.toLowerCase().includes(searchString)
      ) {
        return false
      }
    }

    return true
  }
}
