import { z } from 'zod'
import Model from '../Model'

export default class JobEquipmentHour extends Model {
  static readonly tableName: string = 'jobEquipmentHours'
  static readonly apiEndPoint: string = 'api/job_equipment_hours'
  static readonly sortKey: string = 'created_at'
  static readonly sortDirection = 'desc'
  static readonly bypassPendingRequests:
    | 'all'
    | ('delete' | 'saveMany' | 'create' | 'update')[] = []
  static readonly pusherChannelPrefix = 'job_equipment_hours'

  job_id: string | number = ''
  equipment_id: string | number = ''
  hours_type_id: string | number = ''
  details: string = ''
  estimated_hours: number = 0
  actual_hours: number = 0

  static ZodValidator = z.object({
    details: z
      .string({
        required_error: 'The details field is required.',
      })
      .min(1, 'The details field is required.')
      .max(20000),
    job_id: z.coerce
      .string({
        required_error: 'The job field is required.',
      })
      .min(1, 'The job field is required.'),
    equipment_id: z.coerce
      .string({
        required_error: 'The equipment field is required.',
      })
      .min(1, 'The equipment field is required.'),
    hours_type_id: z.coerce
      .string({
        required_error: 'The hours type field is required.',
      })
      .min(1, 'The hours type field is required.'),
    estimated_hours: z.coerce.number().default(0),
    actual_hours: z.coerce.number().default(0),
  })

  constructor(payload: Partial<JobEquipmentHour> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
