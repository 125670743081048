import { z } from 'zod'
import Model from '../Model'

export default class Image extends Model {
  static readonly tableName: string = 'images'
  static readonly apiEndPoint: string = 'api/images'
  static readonly sortKey: string = 'created_at'
  static readonly bypassPendingRequests:
    | 'all'
    | ('delete' | 'saveMany' | 'create' | 'update')[] = []
  static readonly pusherChannelPrefix = 'images'

  user_id: string | number = ''
  imagable_id: string | number = ''
  imagable_type: 'App\\Models\\Job' | 'App\\Models\\Equipment' =
    'App\\Models\\Job'
  path: string = ''
  url: string = ''
  description: string = ''
  user_name?: string

  static ZodValidator = z.object({
    description: z.string().max(255),
    imagable_id: z.coerce
      .string({
        required_error: 'Images must belong to a job or equipment.',
      })
      .min(1, 'Images must belong to a job or equipment.'),
    imagable_type: z.enum(['App\\Models\\Job', 'App\\Models\\Equipment']),
    url: z.string({
      required_error: 'The url field is required.',
    }),
  })

  constructor(payload: Partial<Image> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
