<template>
  <div
    id="router-container"
    class="flex flex-col"
    :style="{
      height: `${windowHeight}px`,
    }"
  >
    <header
      class="flex flex-row items-center justify-between flex-none shadow bg-primary text-on-primary"
    >
      <section class="flex flex-row items-center">
        <IconButton
          @click="showMenu = !showMenu"
          v-if="authUser && authUser.id"
          :icon="$icons.MENU"
        />
        <h2
          @click="$router.push('/')"
          class="cursor-pointer text-xl sm:text-2xl"
          :class="{ 'ml-2': true || !authUser }"
        >
          {{ appName }}
        </h2>
      </section>
      <section class="flex flex-row items-center">
        <template v-if="!offline">
          <Button
            @click="viewAuthOptions()"
            class="hidden text-on-primary md:flex"
            :icon="$icons.POWER"
            >{{ authUser && authUser.id ? authUser.name : 'Login' }}</Button
          >
          <IconButton
            @click="viewAuthOptions()"
            class="text-secondary md:hidden"
            :icon="$icons.POWER"
          />
          <IconButton @click="refresh()" :icon="$icons.REFRESH" />
        </template>
        <div v-else class="p-1 bg-red-600 rounded-l">
          <Button
            @click="offlineInfo()"
            :icon="$icons.OFFLINE"
            icon-color-class="text-white"
            >Offline</Button
          >
        </div>
      </section>
    </header>
    <PendingRequestsIndicator
      class="absolute top-0 right-0 translate-y-16 -translate-x-2"
    />
    <div class="relative flex-auto h-screen overflow-hidden">
      <transition
        enter-active-class="transition-transform duration-300 ease-out"
        leave-active-class="transition-transform duration-200 ease-in"
        enter-from-class="transform -translate-x-full"
        leave-to-class="transform -translate-x-full"
      >
        <aside
          v-if="showMenu"
          @click="showMenu = false"
          class="absolute top-0 left-0 flex flex-col w-56 h-full p-2 overflow-y-auto bg-white border-r shadow z-4 border-primary"
        >
          <MenuRouterLink to="/" :icon="$icons.DASHBOARD"
            >Dashboard</MenuRouterLink
          >
          <MenuRouterLink
            v-if="$can('edit', 'jobs')"
            to="/jobs"
            :icon="$icons.JOBS"
            >Jobs</MenuRouterLink
          >
          <MenuRouterLink
            v-if="$can('edit', 'equipment') && !offline"
            to="/equipment"
            :icon="$icons.EQUIPMENT"
            >Equipment</MenuRouterLink
          >
          <MenuRouterLink
            v-if="$can('edit', 'checklist') && !offline"
            to="/shopping_list"
            :icon="$icons.SHOPPING_LIST"
            >Shopping list</MenuRouterLink
          >
          <MenuRouterLink
            v-if="$can('view', 'analysis') && !offline"
            to="/analysis"
            :icon="$icons.ANALYSIS"
            >Analysis</MenuRouterLink
          >
          <MenuRouterLink
            v-if="$can('edit', 'users') && !offline"
            to="/users"
            :icon="$icons.USERS"
            >Users</MenuRouterLink
          >
          <MenuRouterLink
            v-if="$can('edit', 'customers') && !offline"
            to="/customers"
            :icon="$icons.CLIENTS"
            >Customers</MenuRouterLink
          >
          <MenuRouterLink
            v-if="$can('edit', 'job_templates') && !offline"
            to="/templates"
            :icon="$icons.TEMPLATES"
            >Job Templates</MenuRouterLink
          >
          <MenuRouterLink
            v-if="$can('edit', 'items') && !offline"
            to="/items"
            :icon="$icons.ITEMS"
            >Items</MenuRouterLink
          >
          <MenuRouterLink
            v-if="$can('edit', 'settings') && !offline"
            to="/settings"
            :icon="$icons.SETTINGS"
            >Settings</MenuRouterLink
          >
        </aside>
      </transition>
      <ModalMaskTransition>
        <ModalMask v-if="showMenu" @close="showMenu = false" />
      </ModalMaskTransition>
      <slot v-if="loaded" />
      <div v-else class="w-full my-12 text-xl text-center">Loading Data</div>
    </div>
    <div
      class="absolute top-0 bottom-0 left-0 right-0 overflow-hidden z-7"
      :class="{
        'pointer-events-none': !modalsAndMasks.length,
      }"
    >
      <ModalListTransition>
        <component
          v-for="(modalOrMask, index) in modalsAndMasks"
          :key="index"
          :is="modalOrMask.modal"
          :id="index"
          v-bind="modalOrMask.props"
          class="pointer-events-auto"
        />
      </ModalListTransition>
      <Confirm />
      <Alert />
      <Loader />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, Component as ComponentVue, PropType } from 'vue'
import { useAuth, useUser } from '~/plugins/auth'
import { useModals, useShownModals } from '~/plugins/modals'
import ModalMask from '~/components/ModalMask.vue'
import LoginModal from '~/modals/LoginModal.vue'
import UserModal from '~/modals/UserModal.vue'
import User from '~/database/models/User'
import { useDatabase } from '~/plugins/database'
import SelectModal from '~/modals/SelectModal.vue'
import useOffline from '~/composables/useOffline'
import OfflineInfoModal from '~/modals/OfflineInfoModal.vue'
import IconButton from '~/components/IconButton.vue'
import Button from '~/components/Button.vue'
import MenuRouterLink from '~/components/MenuRouterLink.vue'
import ModalMaskTransition from '~/components/ModalMaskTransition.vue'
import ModalListTransition from '~/components/ModalListTransition.vue'
import Confirm from '~/components/Confirm.vue'
import Alert from '~/components/Alert.vue'
import Loader from '~/components/Loader.vue'
import PendingRequestsIndicator from '~/components/PendingRequestsIndicator.vue'

const props = defineProps({
  loaded: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const auth = useAuth()
const authUser = useUser()
const modals = useModals()
const appName = ref('Seppanen Construction')
const showMenu = ref(false)
function refresh() {
  window.location.reload()
}

const windowHeight = ref(document.documentElement.clientHeight)
window.addEventListener('resize', function () {
  windowHeight.value = document.documentElement.clientHeight
})
setTimeout(() => {
  windowHeight.value = document.documentElement.clientHeight
}, 1000)

const shownModals = useShownModals()
interface ModalOrMask {
  is_mask?: boolean
  props: Record<string, any>
  modal: ComponentVue //keyof typeof allModals | ''
}
//*This hackery is to get the masks and modals to transition as the should and stack like they should.
const modalsAndMasks = computed(() => {
  if (!shownModals.value?.length) return []
  return shownModals.value.reduce((modalsAndMasks: ModalOrMask[], modal) => {
    return [...modalsAndMasks, { modal: ModalMask, props: {} }, modal]
  }, [])
})

function login() {
  modals?.show(LoginModal)
}

const database = useDatabase()
async function viewAuthOptions() {
  if (!authUser?.value?.id) return login()
  function editProfile() {
    modals.show(UserModal, {
      user: authUser.value as User,
    })
  }
  const dictionary: Record<string, Function | undefined> = {
    Profile: editProfile,
    Logout: auth?.logout,
    'Go Offline': () => database.connection.goOffline(),
  }
  const props = {
    options: Object.keys(dictionary),
    title: authUser?.value?.name,
  }
  const selected = <string>await modals?.show(SelectModal, props)
  if (!selected) return
  const func = dictionary[selected]
  if (!func) return
  func()
}

const offline = useOffline()

function offlineInfo() {
  modals.show(OfflineInfoModal)
}
</script>

<style scoped></style>
