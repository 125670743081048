import { ref, Ref, watch } from 'vue'
import { pendingRequests } from './pendingRequests'
import Storage from '~/assets/constants/Storage'
import { useAuth } from '~/plugins/auth'

class Connection {
  offline: Ref<boolean> = ref(Storage.session.getItem('offline') || false)

  goOffline() {
    Storage.session.setItem('offline', true)
    this.offline.value = true
  }

  async reconnect() {
    Storage.session.setItem('offline', '')
    this.offline.value = false
    const result = await useAuth()
      .getUser(true)
      .catch(() => false)
    if (!result || this.offline.value) return 'fail'
    pendingRequests.performPendingRequests()
    return 'success'
  }
}

export const connection = new Connection()
