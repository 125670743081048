import { z } from 'zod'
import Model from '../Model'

export default class Note extends Model {
  static readonly tableName: string = 'notes'
  static readonly apiEndPoint: string = 'api/notes'
  static readonly sortKey: string = 'created_at'
  static readonly sortDirection = 'desc'
  static readonly bypassPendingRequests = []
  static readonly pusherChannelPrefix = 'notes'

  notable_id: number | string = ''
  notable_type: 'App\\Models\\Job' | 'App\\Models\\Equipment' =
    'App\\Models\\Job'
  user_id: number | string = ''
  note: string = ''

  static ZodValidator = z.object({
    notable_id: z.coerce
      .string({
        required_error: 'The notable field is required.',
      })
      .min(1, 'The notable field is required.'),
    notable_type: z.enum(['App\\Models\\Job', 'App\\Models\\Equipment'], {
      required_error: 'The notable field is required.',
    }),
    note: z
      .string({
        required_error: 'The note field is required.',
      })
      .min(1, 'The note field is required.')
      .max(20000),
  })

  constructor(payload: Partial<Note> = {}) {
    super(payload)
    Object.assign(this, payload)
  }
}
