<template>
  <Modal>
    <template #title>{{ item.name }}</template>
    <div v-if="!item.boolean">
      <Textfield
        v-if="showEstimatedQty"
        v-model="model.estimated_qty"
        @keydown="errors.estimated_qty = ''"
        id="estimated_qty"
        :error="errors.estimated_qty"
        type="number"
      >
        Estimated {{ item.units || 'Qty' }}
      </Textfield>
      <Textfield
        v-model="model.actual_qty"
        @keydown="errors.actual_qty = ''"
        id="actual_qty"
        :error="errors.actual_qty"
        type="number"
      >
        Total {{ item.units || 'Qty' }}
      </Textfield>
    </div>
    <Checkbox
      v-else
      @update:model-value="toggleSelected()"
      :model-value="!!model.actual_qty"
    >
      Selected
    </Checkbox>
    <CustomFieldValues
      v-model="model.custom_field_values"
      fieldable_type="App\Models\Item"
      :fieldable_id="item.id"
    />
    <Textarea
      v-model="model.details"
      @keydown="errors.details = ''"
      :error="errors.details"
    >
      Details
    </Textarea>
    <template #actions>
      <CloseButton @click="$modals.close()" />
      <DeleteButton v-if="showDelete" @click="deleteItem()" />
      <SaveButton @click="saveItem()" />
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { useModals } from '~/plugins/modals'
import { useConfirm } from '~/plugins/confirm'
import { useLoader } from '~/plugins/loader'
import { useSettings } from '~/composables/useSettings'
import { useCan } from '~/plugins/auth'
import JobItem from '~/database/models/JobItem'
import Job from '~/database/models/Job'
import { useModelEdits } from '~/database'
import { useStore } from '~/plugins/store'
import Modal from '~/components/Modal.vue'
import Textfield from '~/components/Textfield.vue'
import Checkbox from '~/components/Checkbox.vue'
import CustomFieldValues from '~/components/CustomFieldValues.vue'
import Textarea from '~/components/Textarea.vue'
import CloseButton from '~/components/CloseButton.vue'
import DeleteButton from '~/components/DeleteButton.vue'
import SaveButton from '~/components/SaveButton.vue'
import { AxiosError } from 'axios'

const props = defineProps({
  jobItem: {
    type: Object as PropType<Partial<JobItem>>,
    default: () => ({}),
  },
  resolve: {
    type: Function as PropType<Function>,
    required: true,
  },
  axiosError: {
    type: [Object, undefined] as PropType<AxiosError | undefined>,
    default: () => undefined,
  },
  bypassQueue: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  local: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const { model, errors, deleteModel, save } = useModelEdits<JobItem>(
  props.jobItem,
  JobItem,
  ['estimated_qty', 'actual_qty', 'details'],
  props.resolve,
  {
    axiosError: props.axiosError,
    bypassQueue: props.bypassQueue,
    deleteMessage: 'Remove this item from this job?',
  },
)

const showDelete = computed(() => {
  return !!model.value.id || props.local
})

//item
const store = useStore()
const item = computed(() => {
  return store.items.record.value[model.value.item_id]
})

//boolean toggle selected
function toggleSelected() {
  model.value.actual_qty = !!model.value.actual_qty ? 0 : 1
}

//estimated qty
const settings = useSettings()
const can = useCan()
const showEstimatedQty = computed(() => {
  const setting = settings.getSetting('show_estimated_qtys')
  return setting == 'all' || (setting == 'admin' && can('edit', 'jobs_admin'))
})

const loader = useLoader()
const modals = useModals()
const confirm = useConfirm()
async function deleteItem() {
  if (!props.local) return deleteModel()
  const confirmed = await confirm('Remove this item from this job?')
  if (!confirmed) return
  props.resolve('deleted')
  modals.close()
}

async function saveItem() {
  if (props.local) {
    props.resolve({
      ...model.value,
      changed: true, //lets back end know we need to update this job item
    })
    return modals.close()
  }
  save()
}
</script>

<style scoped></style>
